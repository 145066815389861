<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="form.content" placeholder="content" />
        </a-form-model-item>

        <a-form-model-item>
          <a-input v-model="form.trackId" placeholder="trackId" />
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker v-model="form.logDate" />
        </a-form-model-item>

        <a-space style="margin-top: 4px">
          <a-button type="primary" @click="query">查询</a-button>
          <a-button @click="reset">重置</a-button>
        </a-space>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="Line">
          <template slot-scope="text">
            <span v-if="text.line">
              <div v-html="text.line"></div>
            </span>
            <span v-else>--</span>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchSystemList } from "@/api/setting/log";
export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize, form } = this;
      const { logDate } = form;

      let logDateEnd, logDateStart;

      if (Array.isArray(logDate) && logDate.length === 2) {
        logDateStart = logDate[0].format("YYYY-MM-DD HH:mm:ss");
        logDateEnd = logDate[1].format("YYYY-MM-DD HH:mm:ss");
      }
      fetchSystemList({
        pageNum: current,
        pageSize,
        ...form,
        logDate: undefined,
        logDateStart,
        logDateEnd,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = typeof res.totalSize === "number" ? res.totalSize : 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
  },
};
</script>